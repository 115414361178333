@import "../global.scss";

.header {
  margin-bottom: 15px;
}
.title {
  width: 60%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  position: relative;
  margin: 10px 0;
  padding: 8px 0 8px 8px;
  border-left: 6px solid $color-blue;
  border-bottom: 6px solid $color-blue;
  z-index: 99;
  @include mobile-large {
    width: 100%;
    position: relative;
    padding: 8px 0 8px 15px;
  }
  @include tablet-large {
    padding: 8px 0 10px 20px;
  }
  &_name {
    font-family: $font-header;
    font-size: $base-size * 2;
    letter-spacing: 2px;
    line-height: 1;
    @include mobile-large {
      font-size: $base-size * 2.2;
    }
    @include tablet-small {
      font-size: $base-size * 2.5;
    }
    @include tablet-large {
      font-size: $base-size * 3.2;
      letter-spacing: 4px;
    }
    a {
      color: $color-text-dark;
    }
    a:hover {
      color: $color-text-dark !important;
    }
  }
  &_subtitle {
    font-size: $base-size * 0.7;
    font-weight: 600;
    letter-spacing: 2px;
    @include mobile-large {
      font-size: $base-size * 0.9;
    }
    @include tablet-large {
      font-size: $base-size * 1.1;
      letter-spacing: 3px;
    }
  }
  &_hamburger {
    position: absolute;
    right: -68%;
    top: 0;
    height: 48px;
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 8px;
    border-top: 6px solid $color-blue;
    border-right: 6px solid $color-blue;
    @include mobile-large {
      display: none;
    }
    .hamburger_line {
      height: 3px;
      width: 28px;
      background: $color-text-dark;
      transition: $transition;
    }
    .hamburger_top {
      transform: none;
      transform-origin: left top;
      margin-bottom: 5px;
      &--open {
        transform: translateX(-2px) translateY(4px) rotate(45deg);
      }
    }
    .hamburger_middle {
      opacity: 1;
      transform: none;
      &--open {
        opacity: 0;
        transform: translateX(-18px);
      }
    }
    .hamburger_bottom {
      transform: none;
      transform-origin: left top;
      margin-top: 5px;
      &--open {
        transform: translateX(-2px) translateY(-2px) rotate(-45deg);
      }
    }
  }
  &_social {
    display: none;
    position: absolute;
    top: 40px;
    right: 30px;
    width: 170px;
    background: white;
    z-index: 1;
    a {
      color: $color-blue;
      font-size: $base-size * 2.5;
      margin: 10px;
      &:hover {
        @include tablet-large {
          transition: $transition;
          color: $color-grey;
        }
      }
      @include tablet-small {
        margin: 15px;
      }
      @include tablet-large {
        margin: 20px;
        font-size: $base-size * 3;
      }
    }
    @include mobile-large {
      display: block;
    }
    @include tablet-small {
      right: 30px;
      top: 45px;
      width: 195px;
    }
    @include tablet-large {
      right: 50px;
      top: 55px;
      width: 241px;
    }
  }
  @include mobile-large {
    width: 100%;
    position: relative;
    padding: 8px 0 8px 15px;
  }
  @include tablet-large {
    padding: 8px 0 10px 20px;
  }
}

.menu {
  position: absolute;
  left: -110vw;
  width: 100vw;
  z-index: 99;
  text-align: right;
  background-color: $color-background;
  box-shadow: 0px 80px 40px -5px rgba(17, 17, 17, 0.6);
  border-bottom: 6px solid $color-blue;
  transition: $transition;
  @include mobile-large {
    width: 100%;
    position: relative;
    left: 0;
    padding: 0;
    border-bottom: none;
    transition: none;
    box-shadow: none;
  }
  @include tablet-large {
    z-index: 0;
  }
  &_list {
    list-style: none;
    padding: 10px 20px 10px 0;
    margin: 0;
    @include mobile-large {
      display: flex;
      justify-content: space-evenly;
      padding: 0;
      padding-top: 10px;
    }
    @include tablet-large {
      justify-content: center;
      padding: 18px 0 10px 0;
    }
  }
  &_item {
    padding: 4px;
    margin: 15px 0;
    font-weight: 600;
    text-transform: uppercase;
    a {
      border-left: 6px solid $color-background;
      padding-left: 20px;
      &:hover {
        @include tablet-large {
          transition: $transition;
          padding-bottom: 4px;
          border-bottom: 5px solid $color-blue;
        }
      }
      &.active {
        border-left: 6px solid $color-blue;
        @include mobile-large {
          border-left: none;
          border-bottom: 5px solid $color-blue;
        }
        @include tablet-large {
          border-bottom-color: $color-blue;
          padding-bottom: 4px;
        }
      }
      @include mobile-large {
        border-left: none;
        border-bottom: 5px solid $color-background;
        margin: 10px 0;
        font-size: $base-size * 1.1;
        padding-left: 0;
      }
      @include tablet-large {
        font-size: $base-size * 1.5;
        padding-bottom: 12px;
        border-bottom: 5px solid $color-background;
      }
    }
    @include tablet-large {
      margin: 15px 25px;
    }
  }
  @include mobile-large {
    width: 100%;
    position: relative;
    left: 0;
    padding: 0;
    transition: none;
    box-shadow: none;
  }
  @include tablet-large {
    z-index: 0;
  }
}
.menu--open {
  left: 0;
  border-bottom: 6px solid $color-blue;
}