@import "../global.scss";

.contact {
  @include section;
  &_title {
    @include title;
  }
  &_socials {
    text-align: center;
    margin: 10px auto;
    font-size: $base-size;
    @include border-shadow;
    @include tablet-small {
      font-size: $base-size * 1.3;
    }
    @include tablet-large {
      font-size: $base-size * 1.4;
    }
  }
  [class*="col-"] {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  &_social {
    font-size: $base-size * 4;
    color: $color-grey;
    &:hover {
      transition: $transition;
      color: $color-blue;
    }
  }
}