.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
  transform: translateY(20px);
}
.fade-appear section,
.fade-enter section {
  transform: translateY(10px);
  opacity: 0;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 400ms linear 200ms, transform 400ms ease-out 200ms;
}
.fade-appear-active section,
.fade-enter.fade-enter-active section {
  transform: translateY(0);
  opacity: 1;
}
.fade-appear-active section:nth-child(2),
.fade-enter.fade-enter-active section:nth-child(2) {
  transition: opacity 400ms linear 400ms, transform 400ms ease-out 400ms;
}
.fade-appear-active section:nth-child(3),
.fade-enter.fade-enter-active section:nth-child(3) {
  transition: opacity 400ms linear 500ms, transform 400ms ease-out 500ms;
}
.fade-appear-active section:nth-child(4),
.fade-enter.fade-enter-active section:nth-child(4) {
  transition: opacity 400ms linear 600ms, transform 400ms ease-out 600ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 200ms linear, transform 200ms ease-in;
}