@import "./variables.scss";
@import "./mixins.scss";
@import "./normalize.scss";
//fonts
@import url("https://fonts.googleapis.com/css?family=Hammersmith+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: $color-text;
  background: $color-background;
  font-family: $font-text;
  font-size: ($base-size * 1.3);
  overflow-y: scroll;
}

.container {
  max-width: 1200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: $color-text;
  transition: 0.3s;
}
a,
a:hover,
a:visited {
  text-decoration: none;
}
a:hover {
  color: inherit;
}