// media query
@mixin mobile-large {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet-small {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tablet-large {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin section {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

@mixin title {
  font-size: $base-size * 1.3;
  font-weight: 600;
  letter-spacing: 1px;
  color: $color-text;
  text-transform: uppercase;
  padding: 8px 10px;
  margin-top: 5px;
  border-bottom: 6px solid $color-blue;
}

@mixin title-right {
  font-size: $base-size * 1.3;
  font-weight: 600;
  letter-spacing: 1px;
  color: $color-text;
  text-transform: uppercase;
  padding: 8px 10px;
  margin-top: 5px;
  border-bottom: 6px solid $color-blue;
}

@mixin border-shadow {
  border-bottom: 6px solid $color-blue;
  border-left: 6px solid $color-blue;
  box-shadow: -13px 13px 20px -11px rgba(17, 17, 17, 0.55);
}

@mixin border-shadow-right {
  border-bottom: 6px solid $color-blue;
  border-right: 6px solid $color-blue;
  box-shadow: -13px 13px 20px -11px rgba(17, 17, 17, 0.55);
}
