@import "../global.scss";

.footer {
  display: flex;
  max-width: 1170px;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  margin: 0 auto;
  flex-direction: column-reverse;
  border-top: 4px solid $color-grey;
  background-color: $color-background;
  @include mobile-large {
    border-top: 6px solid $color-grey;
  }
  @include tablet-large {
    flex-direction: row;
    justify-content: space-between;
  }
  &_menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0;
    padding-top: 8px;
    margin: 0;
    @include mobile-large {
      padding-top: 15px;
    }
    @include tablet-small {
      justify-content: center;
    }
    @include tablet-large {
      padding: 25px;
    }
    li {
      list-style: none;
      font-size: $base-size;
      @include mobile-large {
        font-size: $base-size * 1.2;
      }
      @include tablet-small {
        margin: 0 20px;
      }
      @include tablet-large {
        font-size: $base-size * 1.3;
      }
      .footer_link {
        color: $color-text-light;
        &.active {
          font-weight: 600;
        }
      }
      @include tablet-small {
        margin: 0 20px;
      }
    }
    @include mobile-large {
      padding-top: 15px;
    }
    @include tablet-small {
      justify-content: center;
    }
    @include tablet-large {
      padding: 25px;
    }
  }
  &_socials {
    text-align: center;
    font-size: $base-size;
    margin-top: 5px;
    margin-bottom: 8px;
    color: $color-text-light;
    a {
      margin-right: 14px;
      color: $color-text-light;
      @include mobile-large {
        margin-right: 20px;
      }
    }
    @include tablet-large {
      padding: 25px;
      margin: 0;
      font-size: $base-size * 1.3;
    }
    .footer_social {
      font-size: $base-size * 1.2;
      color: $color-text-light;
      opacity: 0.9;
      &--hidden {
        display: none;
        @include mobile-large {
          display: inline;
        }
      }
      @include tablet-large {
        font-size: $base-size * 1.3;
      }
    }
  }
}