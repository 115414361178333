$color-text-dark: #111;
$color-text: #545454;
$color-text-light: #737373;
$color-background: #fff;
$color-blue: #0b486b;
$color-grey: #a6a6a6;

$font-header: "Hammersmith One", sans-serif;
$font-text: "Montserrat", sans-serif;
$font-footer: "Open Sans", sans-serif;

$radius: 5px;
$base-size: 14px;
$transition: all 0.2s ease-in-out;