@import "../global.scss";

.main {
  flex-grow: 1;
  padding: 20px 10px;
  position: relative;
  @include tablet-large {
    padding: 15px 0;
  }
}
.wrapper {
  position: absolute;
  right: 0;
  left: 0;
  padding-bottom: 70px;
  @include mobile-large {
    padding-bottom: 100px;
  }
}
