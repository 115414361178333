@import "../global.scss";

.skills {
  @include section;
  &_stack {
    padding: 24px 0;
    @include border-shadow;
  }
  &_stack:last-child {
    margin-bottom: 20px;
  }
  &_title {
    @include title;
    display: inline-block;
    margin-top: 30px;
  }
  &_title:nth-child(1) {
    margin-top: 0;
  }
  .row {
    text-align: center;
  }
  [class*="col-"] {
    margin: 10px auto;
    color: $color-grey;
  }
  &_icon {
    font-size: $base-size * 4.4;
    @include tablet-large {
      font-size: $base-size * 4.8;
    }
  }
  &_lang {
    display: block;
    font-size: $base-size * 1.2;
    @include tablet-large {
      font-size: $base-size * 1.4;
    }
  }
}