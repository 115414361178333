@import "../global.scss";

.home {
  @include section;
  @include tablet-small {
    padding: 15px;
  }
  &_title {
    @include title;
    display: inline-block;
  }
  &_text {
    font-size: $base-size * 1.1;
    padding: 15px;
    text-align: justify;
    @include border-shadow;
    @include tablet-large {
      font-size: $base-size * 1.6;
    }
  }
}